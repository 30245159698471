<template>
    <el-dialog
        class="cash-rest-dialog"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        width="600px"
        :close-on-click-modal="false"
    >
        <el-scrollbar style="height:100%">
            <el-form :model="form" v-loading="loading">
                <el-date-picker
                    v-model="dateTime"
                    value="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期"
                    @change="dateChange"
                >
                </el-date-picker>
                <div
                    class="parenter-item"
                    v-for="(item, itemIndex) in form.data"
                    :key="itemIndex"
                >
                    <el-row>{{ item.partnerAlias }}</el-row>
                    <el-row
                        type="flex"
                        align="middle"
                        justify="space-between"
                        v-for="(o, oIndex) in item.financeAccountBalanceList"
                        :key="oIndex"
                    >
                        <span
                            class="item-name"
                            v-if="o.accountTitle.length < 9"
                        >
                            {{ o.accountTitle }}
                        </span>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="o.accountTitle"
                            placement="top"
                            v-else
                        >
                            <span class="item-name">
                                {{ o.accountTitle }}
                            </span>
                        </el-tooltip>
                        <el-form-item>
                            <el-select v-model="o.currency">
                                <el-option value="RMB" label="RMB"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input-number
                                v-model="o.balanceAmount"
                                controls-position="right"
                            ></el-input-number>
                        </el-form-item>
                    </el-row>
                    <el-divider></el-divider>
                </div>
            </el-form>
        </el-scrollbar>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
// import tradeSvc from 'services/trade'
import financeSvc from 'services/finance'
export default {
    name: 'CashRestDia',
    components: {},
    props: {
        parenterId: {
          type: Number,
          default: null
        }
    },
    data() {
        return {
            visible: false,
            title: '余额填写',
            form: {
                data: []
            },
            // date: '',
            loading: false,
            dateTime:''
        }
    },
    watch: {
        async visible(newValue, oldValue) {
            if (newValue) {
                this.getData()
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            console.log(this.parenterId)
            const { data = [] } = await financeSvc.getCashByDate(
                this.$moment(this.dateTime).format('YYYY-MM-DD'),
                this.parenterId
            )
            data.forEach(item => {
                item.financeAccountBalanceList.forEach(o => {
                    o.currency = o.currency ? o.currency : 'RMB'
                })
            })
            this.form.data = data
            this.loading = false
        },
        async handleConfirm() {
            const lists = this.form.data.map(
                item => item.financeAccountBalanceList
            )
            const param = lists.flat()
            param.forEach(item => {
                item.balanceDate = this.$moment(this.dateTime).format('YYYY-MM-DD')
            })
            await financeSvc.addCashRest(param)
            this.$message.success('保存成功！')
            this.$listeners['get-cash-reset']()
            this.visible = false
        },
        dateChange() {
            this.getData()
        },
        handleCancel() {
            this.visible = false
        }
    }
}
</script>
<style lang="scss" scoped>
.cash-rest-dialog {
    ::v-deep .el-dialog__body {
        padding: 0;
        height: 600px;
        // overflow-y: auto;
    }
    ::v-deep .el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;
        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            border-radius: 8px;
            font-size: 14px;
        }
    }
    ::v-deep .el-form {
        padding: 0 30px;
        .el-date-editor {
            width: 240px;
        }
        .el-form-item {
            margin-bottom: 0;
            margin-left: 20px;
        }
        .el-input__inner {
            border-radius: 0;
        }
        .el-row {
            line-height: 40px;
        }
        .el-row + .el-row {
            margin-top: 10px;
        }
        .item-name {
            text-indent: 1em;
            width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        // .item-name:hover::after {
        //   content: attr(data-title);
        //   display: inline-block;
        //   padding: 10px 14px;
        //   border-radius: 5px;
        //   color: #333;
        //   position: absolute;
        //   top: -50px;
        //   left: 30px;
        //   background-color: #f8f8f8;
        // }
    }
}
</style>
